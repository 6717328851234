import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { useTheme } from "@mui/material/styles";
import ImageList from "@mui/material/ImageList";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ImageListItem from "@mui/material/ImageListItem";
import MobileStepper from "@mui/material/MobileStepper";
import Card from "@mui/material/Card";
import { useState } from "react";
import { TextField } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

import Navbar from '../components/navbar.jsx';
import './demo.css'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "1",
    imgPath: "images/simon-kadula--gkndM1GvSA-unsplash.jpg",
    desc: "Grow your business with SATERI",
  },
  {
    label: "2",
    imgPath: "images/hunters-race-MYbhN8KaaEc-unsplash.jpg",
    desc: "Quick repair and service solutions",
  },
  {
    label: "3",
    imgPath: "images/jason-leung-poI7DelFiVA-unsplash.jpg",
    desc: "Manage your resturant with us",
  },
];
const itemData = [
  {
    img: "images/zip20.jpg",
    title: "Breakfast",
    rows: 2,
    cols: 2,
  },
  {
    img: "images/handy.jpg",
    title: "Burger",
  },
  {
    img: "images/t10.jpg",
    title: "Coffee",
    cols: 2,
  },
  {
    img: "images/t20.png",
    title: "Hats",
    cols: 2,
  },
];
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" color="white">
      {"Copyright © "}
      <Link color="inherit" href="">
        sateriservices.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Demo() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 3;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [password, setPassword] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    console.log(firstName, lastName, email, dateOfBirth, password);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Navbar/>
      <main>
        {/* Hero unit */}
        <Box style={{ maxWidth: "100%" }}>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {images.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <>
                    <Box
                      component="img"
                      sx={{
                        display: "block",

                        width: "100%",
                      }}
                      style={{
                        maxWidth: "100%",
                        height: "700px",
                        objectFit: "cover",
                        opacity: "0.4",
                      }}
                      src={step.imgPath}
                    />
                  </>
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <div
            
            style={{
              position: "absolute",
              top: "30%",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className="heroSection"
          >
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight
                  style={{ color: "#1976d2", fontSize: "50px" }}
                />
              ) : (
                <KeyboardArrowLeft
                  style={{ color: "#1976d2", fontSize: "50px" }}
                />
              )}
            </Button>
            <div>
              <h1
                className="heroSectionHeading"
              >
                {images[activeStep]?.desc}
              </h1>
              <p style={{ marginTop: "0px",fontSize: "20px",
                  fontWeight: "400", }}>
               At Sateri Electronic Services we help businesses to meet their true pontential.
              </p>
              <Button variant="contained">Learn more</Button>
            </div>
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft
                  style={{ color: "#1976d2", fontSize: "50px" }}
                />
              ) : (
                <KeyboardArrowRight
                  style={{ color: "#1976d2", fontSize: "50px" }}
                />
              )}
            </Button>
          </div>
        </Box>
        <Box
          sx={{
            backgroundImage: "images/jason-leung-poI7DelFiVA-unsplash.jpg",
            pt: 8,
            pb: 6,
          }}
        >
          <div
            className="aboutSeaction"
          >
            <div  className="aboutSeactionContent">
              <Typography
                className="aboutSeactionContentTitle"
                style={{
                  fontSize: "50px",
                  fontWeight: "700",
                  fontFamily: "Roboto,sans-serif",
                }}
                color="text.primary"
                gutterBottom
              >
                Welcome to Sateri Electronic Services
              </Typography>
              <Typography color="text.secondary" paragraph>
                Sateri Electronics Services is a premier provider specializing in POS 
                software development, along with a key focus on sales and services of 
                Trucount billing machines. Our tailored solutions are designed to elevate business 
                operations, offering user-friendly interfaces and robust functionality. With a commitment 
                to excellence, our skilled technicians ensure seamless operation and optimum performance of 
                Trucount billing machines. Choose Sateri Electronics Services for innovative electronic solutions 
                that enhance efficiency and customer experiences in the dynamic business landscape.
              </Typography>
              
            </div>

            <div className="aboutSeactionGrid">
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={4}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    style={{ height: "150px", width: "150px" }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <PointOfSaleIcon
                          style={{ fontSize: "75px" }}
                        ></PointOfSaleIcon>
                      </div>
                      <Typography style={{ textAlign: "center" }}>
                        Billing Machine
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    style={{ height: "150px", width: "150px" }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <CurrencyRupeeIcon
                          style={{ fontSize: "75px" }}
                        ></CurrencyRupeeIcon>
                      </div>
                      <Typography style={{ textAlign: "center" }}>
                        Note Counting Machine
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    style={{ height: "150px", width: "150px" }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <WysiwygIcon style={{ fontSize: "75px" }}></WysiwygIcon>
                      </div>
                      <Typography style={{ textAlign: "center" }}>
                        Softwares
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    style={{ height: "150px", width: "150px" }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <SupportAgentIcon
                          style={{ fontSize: "75px" }}
                        ></SupportAgentIcon>
                      </div>
                      <Typography style={{ textAlign: "center" }}>
                        Support
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    style={{ height: "150px", width: "150px" }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <Box
                          component="img"
                          sx={{
                            display: "block",

                            width: "100%",
                          }}
                          style={{
                            width: "60px",
                            height: "60px",
                            margin: "auto",
                            marginTop: "10px",
                            marginBottom: "10px",
                            objectFit: "cover",
                          }}
                          src={"images/slip.png"}
                        />
                      </div>
                      <Typography style={{ textAlign: "center" }}>
                        Paper rolls
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    style={{ height: "150px", width: "150px" }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <Box
                          component="img"
                          sx={{
                            display: "block",

                            width: "100%",
                          }}
                          style={{
                            width: "60px",
                            height: "60px",
                            margin: "auto",
                            marginTop: "10px",
                            marginBottom: "10px",
                            objectFit: "cover",
                          }}
                          src={"images/car-battery.png"}
                        />
                      </div>
                      <Typography style={{ textAlign: "center" }}>
                        Inverter
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
        <Box
          sx={{
            backgroundImage: "images/jason-leung-poI7DelFiVA-unsplash.jpg",
            pt: 8,
            pb: 6,
          }}
        >
          <div
            className="section2"
          >
            <div className="section2ImageGrid">
              <ImageList
                sx={{ height: 450 }}
                cols={2}
                rowHeight={164}
              >
                {itemData.map((item) => (
                  <ImageListItem key={item.img}>
                    <img
                      style={{
                        height: "100px",
                        weight: "160px",
                        margin: "auto",
                      }}
                      src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                      srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
              {/* <Box
                component="img"
                sx={{
                  display: "block",

                  width: "100%",
                }}
                style={{
                  width: "60px",
                  height: "60px",
                  margin: "auto",
                  marginTop: "10px",
                  marginBottom: "10px",
                  objectFit: "cover",
                }}
                src={"images/zip20.jpg"}
              /> */}
            </div>
            <div className="bMSeaction">
              <Typography
                style={{
                  fontSize: "50px",
                  fontWeight: "700",
                  fontFamily: "Roboto,sans-serif",
                }}
                color="text.primary"
                gutterBottom
              >
                TROCOUNT - Electronic Cash Register
              </Typography>
              <Typography
                style={{
                  fontSize: "25px",
                  fontWeight: "500",
                  fontFamily: "Roboto,sans-serif",
                }}
                color="text.primary"
                gutterBottom
              >
                Billing Machines for Restautrants, Hotels, Shopping malls and
                more ...
              </Typography>
              <Typography color="text.secondary" paragraph>
                Trucount is an advanced Electronic Cash Register meticulously designed to cater to a diverse 
                range of businesses, making it the ideal choice for retailers, sweet shops, fast-food establishments, restaurants, grocery stores, and more. With its versatile features and user-friendly interface, Trucount streamlines transactions and enhances efficiency for a seamless business experience. Its adaptability and precision make it a reliable tool for managing sales in various retail environments, ensuring accurate and swift transactions. Trucount stands as a trusted solution for businesses seeking a technologically advanced and user-centric Electronic Cash Register.
              </Typography>
              <Stack sx={{ pt: 4 }} direction="row" spacing={2}>
                <Link href="https://sateriservices.com/billingMachines" underline="none">
                  <Button variant="contained">More Deatils</Button>
                </Link>
                
                <Button variant="outlined">Call for enquiry</Button>
              </Stack>
            </div>
          </div>
        </Box>
        <Box
          sx={{
            backgroundImage: "images/jason-leung-poI7DelFiVA-unsplash.jpg",
            pt: 8,
            pb: 6,
          }}
          style={{ paddingTop: "32px" }}
        >
          <Typography
            style={{
              fontSize: "50px",
              fontWeight: "700",
              fontFamily: "Roboto,sans-serif",
              paddingLeft: "50px",
            }}
            color="text.primary"
            gutterBottom
          >
            Our services
          </Typography>
          <br></br>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Grid container spacing={8} style={{ width: "90%" }}>
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="div"
                    sx={{
                      // 16:9
                      pt: "56.25%",
                    }}
                    image="images/Inverter-PNG-Transparent-Image.png"
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{ fontWeight: "400" }}
                    >
                      Inverter
                    </Typography>
                    <Typography color="text.secondary" paragraph>
                      Sateri Electronic Services specializes in the sales and services of inverters. 
                      Our commitment lies in providing top-notch solutions for reliable power backup. 
                      Whether you are looking to purchase a new inverter or require expert servicing, our 
                      skilled technicians ensure optimal performance and longevity of your inverter. Trust 
                      Sateri Electronic Services for unparalleled expertise in meeting your power backup needs.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    
                  
                    <Button variant="contained">Call for enquiry</Button>
                  
                    
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="div"
                    sx={{
                      // 16:9
                      pt: "56.25%",
                    }}
                    image="images/PngItem_4284200.png"
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{ fontWeight: "400" }}
                    >
                      Note counting machine
                    </Typography>
                    <Typography color="text.secondary" paragraph>
                      
Sateri Electronic Services is your go-to destination for the sales and services of note counting machines. 
We offer a range of cutting-edge solutions to meet your currency counting needs. Whether you're looking to 
purchase a new note counting machine or need expert servicing for your existing equipment, our dedicated 
technicians ensure precision and efficiency.
                    </Typography>
                  </CardContent>
                  <CardActions>
                  <Link href="https://lighthearted-sprinkles-5e6bfb.netlify.app/noteCountingMachines" underline="none">
                    <Button variant="contained">More Deatils</Button>
                  </Link>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="div"
                    sx={{
                      // 16:9
                      pt: "56.25%",
                    }}
                    image="images/firosnv-photography-e2Dnu5BPhDU-unsplash.jpg"
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{ fontWeight: "400" }}
                    >
                      Software
                    </Typography>
                    <Typography color="text.secondary" paragraph>
                      Sateri Electronic Services excels in developing software that incorporates the latest 
                      technologies, harnesses the power of cloud storage, and prioritizes user-friendly interfaces. 
                      Our software solutions are at the forefront of innovation, leveraging cutting-edge 
                      technologies to provide unparalleled performance. By integrating cloud storage capabilities,
                       we ensure seamless accessibility and data security.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small">Comming soon..</Button>
                    
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Box>
        <Box
          sx={{
            backgroundImage: "images/jason-leung-poI7DelFiVA-unsplash.jpg",
            pt: 8,
            pb: 6,
          }}
          style={{ backgroundColor: "#f3f6f8" }}
        >
          <div
            className="contactSection"
            style={{
              
            }}
          >
            <div className="contactSection1">
              <Typography
                style={{
                  fontSize: "50px",
                  fontWeight: "700",
                  fontFamily: "Roboto,sans-serif",
                  marginBottom: "5px",
                }}
                color="text.primary"
                gutterBottom
              >
                Contact us
              </Typography>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex" }}>
                  <LocalPhoneIcon></LocalPhoneIcon>
                  <Typography
                    color="text.secondary"
                    paragraph
                    style={{ paddingLeft: "10px" }}
                  >
                    +91 - 9822412353
                  </Typography>
                </div>
                <div style={{ paddingLeft: "25px", display: "flex" }}>
                  <EmailIcon></EmailIcon>
                  <Typography
                    color="text.secondary"
                    paragraph
                    style={{ paddingLeft: "10px" }}
                  >
                    support@sateriservices.com
                  </Typography>
                </div>
              </div>

              <Typography
                style={{
                  fontSize: "25px",
                  fontWeight: "500",
                  fontFamily: "Roboto,sans-serif",
                }}
                color="text.primary"
                gutterBottom
              >
                Services locations :
              </Typography>
              <div style={{ display: "flex" }}>
                <LocationOnIcon></LocationOnIcon>
                <Typography
                  color="text.secondary"
                  paragraph
                  style={{ paddingLeft: "10px" }}
                >
                  KOLHAPUR
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <LocationOnIcon></LocationOnIcon>
                <Typography
                  color="text.secondary"
                  paragraph
                  style={{ paddingLeft: "10px" }}
                >
                  SANGLI
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <LocationOnIcon></LocationOnIcon>
                <Typography
                  color="text.secondary"
                  paragraph
                  style={{ paddingLeft: "10px" }}
                >
                  RATNAGIRI
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <LocationOnIcon></LocationOnIcon>
                <Typography
                  color="text.secondary"
                  paragraph
                  style={{ paddingLeft: "10px" }}
                >
                  SINDHUDARG
                </Typography>
              </div>
            </div>
            <div className="contactSection2">
              <form onSubmit={handleSubmit} action={<Link to="/login" />}>
                <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                  <TextField
                    type="text"
                    variant="outlined"
                    color="secondary"
                    label="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    fullWidth
                    required
                  />
                  <TextField
                    type="text"
                    variant="outlined"
                    color="secondary"
                    label="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    fullWidth
                    required
                  />
                </Stack>
                <TextField
                  type="email"
                  variant="outlined"
                  color="secondary"
                  label="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  fullWidth
                  required
                  sx={{ mb: 4 }}
                />
                <TextField
                  variant="outlined"
                  color="secondary"
                  label="Message"
                  multiline
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                  fullWidth
                  sx={{ mb: 4 }}
                />

                <Button variant="contained" type="submit">
                  Submit
                </Button>
              </form>
              {/* <Box
                component="img"
                sx={{
                  display: "block",

                  width: "100%",
                }}
                style={{
                  width: "60px",
                  height: "60px",
                  margin: "auto",
                  marginTop: "10px",
                  marginBottom: "10px",
                  objectFit: "cover",
                }}
                src={"images/zip20.jpg"}
              /> */}
            </div>
          </div>
        </Box>
      </main>
      {/* Footer */}
      <Box
        sx={{ bgcolor: "background.paper", p: 6 }}
        component="footer"
        className="footerSection"
        style={{ backgroundColor: "#191b1d"}}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: "white",
          }}
        >
          <div style={{ flex: 6 }}>
            <Typography
              variant="h6"
              gutterBottom
              className="footerSectionTitle"
            >
              Sateri Electronic Services
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              paragraph
              color="#cccccc"
              className="footerSectionParagraph"
            >
              Sateri Electronics Services is a premier provider specializing in POS software development, along with a key focus on sales and services of Trucount billing machines. Choose Sateri Electronics Services for innovative electronic solutions that enhance efficiency and customer experiences in the dynamic business landscape.
            </Typography>
            
            <div style={{ display: "flex" }}>
                <LocationOnIcon color="#cccccc"></LocationOnIcon>
                <Typography
                  color="#cccccc"
                  paragraph
                  style={{ paddingLeft: "10px" }}
                >
                  KOLHAPUR
                </Typography>
            </div>
            <div style={{ display: "flex" }}>
                  <LocalPhoneIcon></LocalPhoneIcon>
                  <Typography
                    color="#cccccc"
                    paragraph
                    style={{ paddingLeft: "10px" }}
                  >
                    +91 - 9822412353 /  +91 - 8459583479 
                  </Typography>
            </div>
            
          </div>
          <div style={{ flex: 3 }} className="footerSectionQuickLinks">
          <Typography
              variant="h6"
              gutterBottom
              
            >
              Quick Links
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              paragraph
              color="#cccccc"
            >
              About
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              paragraph
              color="#cccccc"
            >
              Services
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              paragraph
              color="#cccccc"
            >
              Billing Machines
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              paragraph
              color="#cccccc"
            >
              Note Counting
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              paragraph
              color="#cccccc"
            >
              Contact 
            </Typography>
            
          </div>
          <div className="footerSectionTC">
          
            <Typography
              variant="subtitle1"
              component="p"
              paragraph
              
            >
              <Link>
              Terms and Contions
              </Link>
              
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              paragraph
              
            >
              <Link>
              Privacy Policy
              </Link>
              
            </Typography>
            <Copyright color="white" />
          </div>
        </div>
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}
