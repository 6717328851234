import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { useTheme } from "@mui/material/styles";
import ImageList from "@mui/material/ImageList";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ImageListItem from "@mui/material/ImageListItem";
import MobileStepper from "@mui/material/MobileStepper";
import Card from "@mui/material/Card";
import { useState } from "react";
import { TextField } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Navbar from "../../components/navbar";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "1",
    imgPath: "images/simon-kadula--gkndM1GvSA-unsplash.jpg",
    desc: "Grow your business with SATERI",
  },
  {
    label: "2",
    imgPath: "images/hunters-race-MYbhN8KaaEc-unsplash.jpg",
    desc: "Quick repair and service solutions",
  },
  {
    label: "3",
    imgPath: "images/jason-leung-poI7DelFiVA-unsplash.jpg",
    desc: "Manage your resturant with us",
  },
];
const itemData = [
  {
    img: "images/zip20.jpg",
    title: "Breakfast",
    rows: 2,
    cols: 2,
  },
  {
    img: "images/handy.jpg",
    title: "Burger",
  },
  {
    img: "images/t10.jpg",
    title: "Coffee",
    cols: 2,
  },
  {
    img: "images/t20.png",
    title: "Hats",
    cols: 2,
  },
];
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" color="white">
      {"Copyright © "}
      <Link color="inherit" href="">
        sateriservices.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function BillingMachinePagePdfViewT90() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [password, setPassword] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    console.log(firstName, lastName, email, dateOfBirth, password);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Navbar/>
      <main>
        <br></br>
        {/* Hero unit */}
        <Box
          sx={{
            
            pt: 8,
            pb: 6,
          }}
          style={{ maxWidth: "100%"  }}
        >
          
          <br></br>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            
            <embed src="pdfs/Trucount T-10.pdf"  style={{width:"90%",height:"1000px"}}  />
          </div>
        </Box>
        
      </main>
      {/* Footer */}
      <Box
        sx={{ bgcolor: "background.paper", p: 6 }}
        component="footer"
        style={{ backgroundColor: "#191b1d", height: "350px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: "white",
          }}
        >
          <div style={{ flex: 6 }}>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                fontSize: "25px",
                fontWeight: "500",
                fontFamily: "Roboto,sans-serif",
              }}
            >
              Sateri Electronic Services
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              paragraph
              color="#cccccc"
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero
              neque illum eligendi veniam perspiciatis dicta incidunt voluptatum
              voluptatibus voluptatem! Nulla, aliquid laborum eos ex magnam
              velit blanditiis omnis obcaecati a perferendis eligendi.
            </Typography>
            
            <div style={{ display: "flex" }}>
                <LocationOnIcon color="#cccccc"></LocationOnIcon>
                <Typography
                  color="#cccccc"
                  paragraph
                  style={{ paddingLeft: "10px" }}
                >
                  KOLHAPUR
                </Typography>
            </div>
            <div style={{ display: "flex" }}>
                  <LocalPhoneIcon></LocalPhoneIcon>
                  <Typography
                    color="#cccccc"
                    paragraph
                    style={{ paddingLeft: "10px" }}
                  >
                    +91 - 9822412353 /  +91 - 8459583479 
                  </Typography>
            </div>
            
          </div>
          <div style={{ flex: 3 }}>
          <Typography
              variant="h6"
              gutterBottom
              style={{
                fontSize: "25px",
                fontWeight: "500",
                fontFamily: "Roboto,sans-serif",
              }}
            >
              Quick Links
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              paragraph
              color="#cccccc"
            >
              > About
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              paragraph
              color="#cccccc"
            >
              > Services
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              paragraph
              color="#cccccc"
            >
              > Billing Machines
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              paragraph
              color="#cccccc"
            >
              > Note Counting
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              paragraph
              color="#cccccc"
            >
              > Contact 
            </Typography>
            
          </div>
          <div style={{ flex: 3, paddingTop:"50px" }}>
          
            <Typography
              variant="subtitle1"
              component="p"
              paragraph
              
            >
              <Link>
              Terms and Contions
              </Link>
              
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              paragraph
              
            >
              <Link>
              Privacy Policy
              </Link>
              
            </Typography>
            <Copyright color="white" />
          </div>
        </div>
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}
