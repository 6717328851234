import * as React from "react";
import AppBar from "@mui/material/AppBar";
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import { useState } from "react";
import './navbar.css'
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const defaultTheme = createTheme();


export default function Navbar() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
 

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppBar
        position="relative"
        style={{
          height: "80px",
          background: "white",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingRight: "35px",
          position: "fixed",
          zIndex: "2",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              display: "block",

              width: "100%",
            }}
            style={{
              width: "165px",
              height: "40px",
              margin: "10px",
              objectFit: "cover",
            }}
            src={"images/sateriLogo.png"}
          />
        </div>

        <div className="navToolbar">
        <Toolbar>
          {/* <CameraIcon sx={{ mr: 2 }} /> */}
          
          <Link href="/" underline="none">
            <Typography
                variant="h6"
                color="#1976d2"
                style={{ padding: "0px 15px" }}
                noWrap
                className="navbarName"
            >
                Home
            </Typography>  
            <div className="dash"></div>
          </Link>

          <Link href="/" underline="none">
            <Typography
                variant="h6"
                color="#1976d2"
                style={{ padding: "0px 15px" }}
                noWrap
                className="navbarName"
            >
                About
            </Typography>
            <div className="dash"></div>
          </Link>

          <Link href="/" underline="none">
            <Typography
                variant="h6"
                color="#1976d2"
                style={{ padding: "0px 15px" }}
                noWrap
                className="navbarName"
            >
                Services
            </Typography>
            <div className="dash"></div>
          </Link>
          
          <Link href="/" underline="none">
            <Typography
                variant="h6"
                color="#1976d2"
                style={{ padding: "0px 15px" }}
                noWrap
                className="navbarName"
            >
                Contact
            </Typography>
            <div className="dash"></div>
          </Link>
          
        </Toolbar>


        </div>
              </AppBar>
    </ThemeProvider>
  );
}
