import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Demo from "./pages/demo";
import BillingMachinePage from './pages/billingMachinePage';
import BillingMachinePagePdfViewT20 from './pages/pdfViews/billingMachinePdfViewT20';
import BillingMachinePagePdfViewT90 from './pages/pdfViews/billingMachinePdfViewT90';
import BillingMachinePagePdfViewZIP20 from './pages/pdfViews/billingMachinePdfViewZIP20';
import BillingMachinePagePdfViewHandy from './pages/pdfViews/billingMachinePdfViewHandy';
import BillingMachinePagePdfViewT10 from './pages/pdfViews/billingMachinePdfViewT10';
import NoteCountingMachinePage from './pages/noteCounting/noteCountingMachinePage'

function App() {


  
  return (
    <Router>
      <div className="app-container">

        <Routes>
          <Route exact path="/" element={<Demo/>} />
        </Routes>
        <Routes>
          <Route exact path="/billingMachines" element={<BillingMachinePage/>} />
        </Routes>
        <Routes>
          <Route exact path="/billingMachinesPdfT20" element={<BillingMachinePagePdfViewT20/>} />
        </Routes>
        <Routes>
          <Route exact path="/billingMachinesPdfT90" element={<BillingMachinePagePdfViewT90/>} />
        </Routes>
        <Routes>
          <Route exact path="/billingMachinesPdfZIP20" element={<BillingMachinePagePdfViewZIP20/>} />
        </Routes>
        <Routes>
          <Route exact path="/billingMachinesPdfHandy" element={<BillingMachinePagePdfViewHandy/>} />
        </Routes>
        <Routes>
          <Route exact path="/billingMachinesPdfT10" element={<BillingMachinePagePdfViewT10/>} />
        </Routes>
        <Routes>
          <Route exact path="/noteCountingMachines" element={<NoteCountingMachinePage/>} />
        </Routes>
       
      </div>
    </Router>
    // <Router>
    //   <Switch>
    //     {/* <Route exact path="/">
    //       {user ? <Home /> : <Register />}
    //     </Route>
    //     <Route path="/login">{user ? <Redirect to="/" /> : <Login />}</Route>
    //     <Route path="/register">
    //       {user ? <Redirect to="/" /> : <Register />}
    //     </Route> */}
    //     <Route path="/">
    //       <demo />
    //     </Route>
    //   </Switch>
    // </Router>
  );
}

export default App;
